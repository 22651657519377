<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-link class="brand-logo">
      <img src="/img/logo-dialog.405b5032.svg" alt="">
    </b-link>
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        
        <b-card-title class="mb-1">
          {{$t("Resend Activation")}}
        </b-card-title>
        <b-card-text class="mb-2">
          {{$t("Enter your email and we'll send you a new activation link")}}
        </b-card-text>
        <!-- form -->
        <validation-observer ref="registerForm" > 
            <b-form
              class="col-md-12 auth-register-form"
              @submit.prevent="validationForm"
            >
              <b-form-group
                :label="$t('Email*')"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="regEmail"
                    :state="errors.length > 0 ? false:null"
                    name="register-email"
                    placeholder="example@domain.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <div class="register-btn">
                <b-button
                  class="btn-register"
                  type="submit"
                >
                  {{$t("Send Activation Link")}}
                </b-button>
              </div>
            </b-form>
          
        </validation-observer>
      </b-card>
       <p class="text-center mt-2">
          <b-link :to="{name:'auth-login-v1'}">
            <feather-icon icon="ChevronLeftIcon" /> {{$t("Back to login")}}
          </b-link>
        </p>
    <!-- /Register v1 -->
    </div>
    <b-link class="mercatus-logo">
      <img src="@/assets/images/logo/logo-mercatus-white.png" alt="">
    </b-link>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup} from 'bootstrap-vue'
import CostLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  components: {
    CostLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',

      required,
      email,
    }
  },
  methods: {
      validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.submitForm()
        }
      })
    },
    submitForm(){
      this.$store.dispatch("users/reSendActivation", { context: this , email:this.regEmail})
      .then(sucess => {
        if(sucess){
           this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Verification Resubmitted',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
        }})
        .catch(() => {
          this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Error'),
                  icon: 'AlertTriangleIcon',    
                  variant: 'danger',
                  text: "Invalid Email or User Already Activated"
                  },
              })
            }
        )
    },
  }  
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
</style>
